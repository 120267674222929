import './css/Mission.css';
import { useTranslation } from 'react-i18next';

// TODO @Falimonda - Add a real email subscription form here
const handleJoin = () => {
  window.location.href = 'https://app.abal.ai';
};

const Mission = () => {
    const { t } = useTranslation();

    return (
      <main className="main-content">
        <h1>{t('mission.title')}</h1>
        <p className="subtitle">
            {t('mission.subtitle')}
        </p>
        <div className="cta-buttons">
          <button className="cta-button" onClick={handleJoin}>
            <span>{t('mission.cta')}</span>
          </button>
        </div>
        <div className="mission-content">
            <p>{t('mission.content.paragraph1')}</p>
            <p>{t('mission.content.paragraph2')}</p>
        </div>
      </main>
    );
};

export default Mission;