import { useTranslation } from 'react-i18next';

import './css/Home.css';
import './css/Features.css';
import LiteParallelTextHistory from './LiteParallelTextHistory';
  
const Home = ({ 
    showVideo, 
    handleTryApp, 
    handleRequestLanguage,
    renderLanguageColumns,
    renderClassicBooks,
  }) => {
    const { t, i18n } = useTranslation();
    const nativeLanguage = i18n.language;
    // const pageContent = {
    //     "association": {
    //         "source_language": "en",
    //         "target_language": "it",
    //         "word_maps": [
    //             {
    //                 "source_to_target": {
    //                     "0": [0],     // "Once" -> part of "C'era una volta"
    //                     "1": [0],     // "upon" -> part of "C'era una volta"
    //                     "2": [0],     // "a" -> part of "C'era una volta"
    //                     "3": [0],     // "time" -> part of "C'era una volta"
    //                     "4": [-1],    // "there" -> no direct equivalent
    //                     "5": [-1],    // "was" -> already implied in "C'era"
    //                     "6": [1],     // "an" -> "una"
    //                     "7": [3],     // "enchanted" -> "incantata"
    //                     "8": [2]      // "forest" -> "foresta"
    //                 },
    //                 "target_to_source": {
    //                     "0": [0, 1, 2, 3],  // "C'era una volta" -> "Once upon a time"
    //                     "1": [6],           // "una" -> "an"
    //                     "2": [8],           // "foresta" -> "forest"
    //                     "3": [7]            // "incantata" -> "enchanted"
    //                 }
    //             },
    //             {
    //                 "source_to_target": {
    //                     "0": [0],     // "A" -> "Una"
    //                     "1": [-1],    // "young" -> no direct equivalent
    //                     "2": [1],     // "girl" -> "bambina"
    //                     "3": [2],     // "named" -> "di nome"
    //                     "4": [3],     // "Lily" -> "Lily"
    //                     "5": [4],     // "discovered" -> "scoprì"
    //                     "6": [5],     // "the" -> "la"
    //                     "7": [7],     // "forest's" -> "della foresta"
    //                     "8": [6]      // "magic" -> "magia"
    //                 },
    //                 "target_to_source": {
    //                     "0": [0],     // "Una" -> "A"
    //                     "1": [2],     // "bambina" -> "girl"
    //                     "2": [3],     // "di nome" -> "named"
    //                     "3": [4],     // "Lily" -> "Lily"
    //                     "4": [5],     // "scoprì" -> "discovered"
    //                     "5": [6],     // "la" -> "the"
    //                     "6": [8],     // "magia" -> "magic"
    //                     "7": [7]      // "della foresta" -> "forest's"
    //                 }
    //             }
    //         ]
    //     },
    //     "learning_content": [
    //         {
    //             "text": "C'era una volta una foresta incantata.",
    //             "words": [
    //                 "C'era una volta",
    //                 "una",
    //                 "foresta",
    //                 "incantata."
    //             ]
    //         },
    //         {
    //             "text": "Una bambina di nome Lily scoprì la magia della foresta.",
    //             "words": [
    //                 "Una",
    //                 "bambina",
    //                 "di nome",
    //                 "Lily",
    //                 "scoprì",
    //                 "la",
    //                 "magia",
    //                 "della foresta."
    //             ]
    //         }
    //     ],
    //     "learning_language": "it",
    //     "native_content": [
    //         {
    //             "text": "Once upon a time, there was an enchanted forest.",
    //             "words": [
    //                 "Once",
    //                 "upon",
    //                 "a",
    //                 "time",
    //                 "there",
    //                 "was",
    //                 "an",
    //                 "enchanted",
    //                 "forest."
    //             ]
    //         },
    //         {
    //             "text": "A young girl named Lily discovered the forest's magic.",
    //             "words": [
    //                 "A",
    //                 "young",
    //                 "girl",
    //                 "named",
    //                 "Lily",
    //                 "discovered",
    //                 "the",
    //                 "forest's",
    //                 "magic."
    //             ]
    //         }
    //     ],
    //     "native_language": "en",
    //     "page_number": 1
    // }
    const pageContent = {
        "association": {
            "source_language": "en",
            "target_language": "zh",
            "word_maps": [
                {
                    "source_to_target": {
                        "0": [
                            0
                        ],
                        "1": [
                            0
                        ],
                        "2": [
                            0
                        ],
                        "3": [
                            0
                        ],
                        "4": [
                            2
                        ],
                        "5": [
                            2
                        ],
                        "6": [
                            3
                        ],
                        "7": [
                            4,
                            5,
                            6,
                            7
                        ],
                        "8": [
                            9
                        ],
                        "9": [
                            10
                        ]
                    },
                    "target_to_source": {
                        "0": [
                            0,
                            1,
                            2,
                            3
                        ],
                        "1": [
                            -1
                        ],
                        "2": [
                            4,
                            5
                        ],
                        "3": [
                            6
                        ],
                        "4": [
                            7
                        ],
                        "5": [
                            7
                        ],
                        "6": [
                            7
                        ],
                        "7": [
                            7
                        ],
                        "8": [
                            -1
                        ],
                        "9": [
                            8
                        ],
                        "10": [
                            9
                        ]
                    }
                },
                {
                    "source_to_target": {
                        "0": [
                            0
                        ],
                        "1": [
                            4
                        ],
                        "2": [
                            4
                        ],
                        "3": [
                            1
                        ],
                        "4": [
                            2
                        ],
                        "5": [
                            5,
                            6
                        ],
                        "6": [
                            7,
                            8
                        ],
                        "7": [
                            8,
                            9
                        ],
                        "8": [
                            10
                        ],
                        "9": [
                            11
                        ]
                    },
                    "target_to_source": {
                        "0": [
                            0
                        ],
                        "1": [
                            3
                        ],
                        "2": [
                            4
                        ],
                        "3": [
                            -1
                        ],
                        "4": [
                            1,
                            2
                        ],
                        "5": [
                            5
                        ],
                        "6": [
                            5
                        ],
                        "7": [
                            6
                        ],
                        "8": [
                            6,
                            7
                        ],
                        "9": [
                            7
                        ],
                        "10": [
                            8
                        ],
                        "11": [
                            9
                        ]
                    }
                }
            ]
        },
        "learning_content": [
            {
                "text": "从前，有一片被施了魔法的森林。",
                "words": [
                    "从前",
                    "，",
                    "有",
                    "一片",
                    "被",
                    "施",
                    "了",
                    "魔法",
                    "的",
                    "森林。"
                ]
            },
            {
                "text": "一个名叫莉莉的小女孩发现了这片森林的魔力。",
                "words": [
                    "一个",
                    "名叫",
                    "莉莉",
                    "的",
                    "小女孩",
                    "发现",
                    "了",
                    "这片",
                    "森林",
                    "的",
                    "魔力。"
                ]
            }
        ],
        "learning_language": "zh",
        "native_content": [
            {
                "text": "Once upon a time, there was an enchanted forest.",
                "words": [
                    "Once",
                    "upon",
                    "a",
                    "time",
                    "there",
                    "was",
                    "an",
                    "enchanted",
                    "forest."
                ]
            },
            {
                "text": "A young girl named Lily discovered the forest's magic.",
                "words": [
                    "A",
                    "young",
                    "girl",
                    "named",
                    "Lily",
                    "discovered",
                    "the",
                    "forest's",
                    "magic."
                ]
            }
        ],
        "native_language": "en",
        "page_number": 1
    }

    return (
        <main className="main-content">
            <h1 id="home-title">{t('home.title')}</h1>

            <p className="subtitle">
                {t('home.subtitle')}
            </p>
            <div className="cta-buttons">
                <button className="cta-button" onClick={handleTryApp}>
                <span>{t('nav.openApp')}</span>
                </button>
            </div>

            <div className="features-container">
                <div className="feature-card">
                <div className="feature-left">
                    <h3>{t('home.languages.title')}</h3>
                    <p>{t('home.languages.description')}</p>
                </div>
                <div className="feature-right">
                    {renderLanguageColumns()}
                </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                <div className="feature-left">
                    <h3>{t('home.classics.title')}</h3>
                    <p>{t('home.classics.description')}</p>
                    <button id="explore-library" onClick={handleTryApp}>
                        {t('home.classics.exploreButton')}
                    </button>
                </div>
                <div className="feature-right">
                    {renderClassicBooks()}
                </div>
                </div>
            </div>

            <h1>{t('home.features.title')}</h1>
            <p className="subtitle">
                {t('home.features.subtitle')}
            </p>

            {/* Shows LiteParallelTextHistory in  */}
            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.wordAssociations.title')}</h3>
                        <p>{t('home.features.wordAssociations.description')}</p>
                    </div>
                    <div className="feature-right">
                        <LiteParallelTextHistory 
                            dyslexicFontEnabled={false}
                            textAlignment="center" 
                            pageContent={pageContent}
                            pagePronunciation={[]}
                            pageFurigana={[]}
                            learningLanguage={'it'} 
                            nativeLanguage={nativeLanguage} 
                            showNativeLanguage={true}
                            showPronunciation={false}
                            showFurigana={false}
                            nativeLanguageDirection={'ltr'}
                            learningLanguageDirection={'ltr'}
                            overrideHoverIndices={{ sentenceIndex: 1, wordIndices: { source: [1,2], target: [4] } }}
                      />
                    </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.phonetics.title')}</h3>
                        <p>{t('home.features.phonetics.description')}</p>
                    </div>
                    <div className="feature-right">
                        <img 
                            src="/assets/features/phonetics.png" 
                            alt={t('home.features.phonetics.imageAlt')} 
                        />
                    </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.interlacedText.title')}</h3>
                        <p>{t('home.features.interlacedText.description')}</p>
                    </div>
                    <div className="feature-right">
                        <img 
                            src="/assets/features/interlacing.png" 
                            alt={t('home.features.interlacedText.imageAlt')} 
                        />
                    </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.readingAids.title')}</h3>
                        <p>{t('home.features.readingAids.description')}</p>
                    </div>
                    <div className="feature-right">
                        <img 
                            src="/assets/features/furigana.png" 
                            alt={t('home.features.readingAids.imageAlt')} 
                        />
                    </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.dyslexia.title')}</h3>
                        <p>{t('home.features.dyslexia.description')}</p>
                    </div>
                    <div className="feature-right">
                        <img 
                            src="/assets/features/dyslexia.png" 
                            alt={t('home.features.dyslexia.imageAlt')} 
                        />
                    </div>
                </div>
            </div>

            <div className="try-abal-section" onClick={handleTryApp}>
                <h2>{t('home.cta.title')}</h2>
                <button id="try-for-free">{t('nav.openApp')}</button>
            </div>

        </main>
    );
  };
  
  export default Home;
